import React from "react"
import { graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import { SITE_TITLE, THEME } from "../constant"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/post-card"

const BlogIndex = ({ data }) => {
  const { edges: posts } = data.allMdx
  return (
    <ThemeProvider theme={THEME}>
      <Layout>
        <SEO></SEO>
        <h1 className="d-none">{SITE_TITLE}</h1>
        <ul>
          {posts.map(({ node: post }) => (
            <li key={post.id} className="mb-5">
              <PostCard {...post.frontmatter} excerpt={post.excerpt} />
            </li>
          ))}
        </ul>
      </Layout>
    </ThemeProvider>
  )
}
export const pageQuery = graphql`
  query blogIndex {
    allMdx {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            slug
            date(formatString: "YYYY-MM-DD")
            tags
            key_image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
export default BlogIndex
